.history {
  padding: rem(170px 0 10px);
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }
  @include media-breakpoint-down(md) {
    padding: 4rem 0 0;
  }
  @include media-breakpoint-down(xs) {
    padding: 1rem 0 0;
  }
  &:before {
    content: "";
    @extend .full;
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 70%,rgba(255,255,255,0) 100%); 
    z-index: 1;
    @include media-breakpoint-down(xs) {
      background: linear-gradient(to bottom,  rgba(255,255,255,1) 90%,rgba(255,255,255,0) 100%); 
    }
  }
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 3;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      padding: 8rem 1rem 2rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 3rem 1rem 0;
    }
  }
  &__text {
    position: relative;
    background: cl(white);
    box-shadow:rem(-10px 25px 60px cl(black, .34));
    border-radius: rem(45px 0);
    padding: rem(80px 270px 85px 100px);
    max-width:rem(760px);
    font-size: rem(18px);
    line-height: 1.5;
    margin: rem(35px 0 0);
    @include media-breakpoint-down(xs) {
      padding: 5rem 2rem 2rem;
    }
  }
  &__info {
    width:rem(620px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(lg) {
      margin: rem(0 0 0 -191px);
    }
    @include media-breakpoint-down(xs) {
      width:100%;
      margin-top: 2rem;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right:0;
      width:rem(500px);
      height: rem(400px);
      z-index: 1;
      background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 75%);
    }
  }
  &__image {
    height: rem(350px);
    border-radius: rem(45px 0);
    overflow: hidden;
    box-shadow:rem(8px -8px 0 cl(primary), -10px 25px 60px cl(black, .34));
    margin: rem(0 0 35px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      height: 14rem;
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__play {
    @extend .pos-center;
    color:cl(white);
    opacity: .5;
    transition: all .2s linear;
    font-size: rem(80px);
    &:hover {
      color:cl(white);
      opacity: .8;
    }
  }
  &__ttl,
  &__txt {
    text-align: right;
    text-transform: uppercase;
    color:cl(primary);
    font-family: $fontLibreBaskerville;
    letter-spacing: rem(5px);
    position: relative;
    z-index: 2;
    white-space: nowrap;
  }
  &__ttl {
    font-size: rem(50px);
    @include media-breakpoint-down(xs) {
      font-size: rem(30px);
    }
  }
  &__txt {
    font-size: rem(110px);
    @include media-breakpoint-down(xs) {
      font-size: rem(70px);
    }
    span {
      position: relative;
    }
  }
  &__leaf {
    position: absolute;
    width:rem(75px);
    height: auto;
    top: -3%;
    right: -33%;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      width: 2.6875rem;
    }
  }
  &__bottom {
    overflow: hidden;
    padding: rem(30px 0 0);
  }
  &__marquee {
    display:inline-block;
    //padding:0 0 0 100%;
    animation: marquee 200s infinite linear;
    position: relative;
    z-index: 3;
    white-space: nowrap;
    span {
      display: inline-block;
      vertical-align: top;
      font-size: rem(200px);
      line-height: 1;
      text-transform: uppercase;
      color:cl(white, .8);
      font-weight: 700;
      -webkit-text-stroke: 1px cl(primary);
      -webkit-text-fill-color: #fff;
      @include media-breakpoint-down(xs) {
        font-size: rem(100px);
      }
      em {
        position: relative;
        font-style: normal;
        &:before {
          content: "";
          width:rem(100px);
          height: rem(150px);
          position: absolute;
          background: url(../img/svg/leaf-1.svg) no-repeat 50% 50%;
          background-size: contain;
          transform: rotate(70deg);
          bottom: 10%;
          left: 53%;
          @include media-breakpoint-down(xs)  {
            width:rem(50px);
            height: rem(100px);
          }
        }
      }
    }
  }
  &__decor {
    position: absolute;
    width: rem(200px);
    height: auto;
    transform: rotate(70deg);
    left: 5%;
    top: -3%;
    z-index: 101;
    @include media-breakpoint-down(xs) {
      width: rem(100px);
      top: -2rem;
    }
  }
}