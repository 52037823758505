.students {
  position: relative;
  z-index: 2;
  padding: rem(200px 0 120px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }
  @include media-breakpoint-down(xs) {
    padding: 4.5rem 0 14rem;
    background-size: 100% auto;
  }
  &:before {
    content: "";
    @extend .full;
    background: linear-gradient(to bottom,  rgba(255,255,255,1) 70%,rgba(255,255,255,0) 100%); 
    z-index: 1;
    @include media-breakpoint-down(xs) {
      background: linear-gradient(to bottom,  rgba(255,255,255,1) 90%,rgba(255,255,255,0) 100%); 
    }
  }
  .container {
    position: relative;
    z-index: 3;
  }
  &__slogan {
    font-size: rem(30px);
    line-height: 1.5;
    color:cl(primary);
  }
  &__text {
    @include media-breakpoint-down(lg) {
        margin-bottom: 5rem;
    }
  }
  &__side {
    position: relative;
    @include media-breakpoint-down(md) {
      transform: scale(0.9);
    }
    @include media-breakpoint-down(sm) {
      transform: scale(0.7);
    }
    @include media-breakpoint-down(xs) {
      transform: scale(1);
    }
  }
  &__decor {
    position: absolute;
    width: rem(200px);
    height: auto;
    transform: rotate(70deg);
    right: 0;
    bottom: -20%;
    filter: drop-shadow(2px 4px 0px white);
    @include media-breakpoint-down(xs) {
      width: rem(100px);
      right:1rem;
    }
  }
  &__list {
    list-style: none;
    width: rem(950px);
    height: rem(436px);
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: rem(60px);
    justify-items: center;
    align-items: center;
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      height:66.4375rem;
      width:20.25rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(9, 1fr);
      margin: 0 auto;
    }
    li {
      z-index: 1;
      grid-column: span 2;
      margin-bottom: -52%;
      width:rem(172px);
      height:rem(172px);
      // -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      //         clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      // -webkit-transform: scale(1);
      //         transform: scale(1);
      @include media-breakpoint-up(sm) {
        &:nth-child(7n + 1) {
          grid-column: 2 / span 2;
        }
      }
      @include media-breakpoint-down(xs) {
        &:nth-child(odd) {
          grid-column: 2 / span 2;
        }
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width:100%;
      padding: rem(30px);
      position: relative;
      transition: transform .3s ease;
      &:hover {
        transform: scale(1.1);
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width:100%;
        background: cl(white);
        border-radius: rem(15px);
        transform: rotate(45deg);
        box-shadow:rem(0 11px 35px cl(black, .16));
      }
      img {
        position: relative;
        z-index: 2;
        max-width: 100%;
        height: auto;
      }
    }
  }
  &__image {
    position: absolute;
    right:0;
    bottom: rem(-160px);
    width:rem(1109px);
    height: rem(977px);
    -webkit-mask: url(../img/image-mask.png);
    -webkit-mask-size: 100%;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    z-index: 2;
    @include media-breakpoint-down(md) {
      width: 90%;
    }
    @include media-breakpoint-down(xs)  {
      height: 23.0625rem;
      bottom: 56rem;
    }
  }
  &__pic {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width:100%;
      background: rgba(75, 144, 206, .22);
    }
  }
  &__leaf {
    position: absolute;
    width: rem(150px);
    height: auto;
    transform: rotate(70deg);
    left: 5%;
    top: -8%;
    z-index: 101;
    @include media-breakpoint-down(xs) {
      width: rem(100px);
      top:-4rem;
    }
  }
}