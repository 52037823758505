.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
    transition: background .3s ease;
	@include media-breakpoint-down(lg){
		//padding: 0 2rem;
	}
	@include media-breakpoint-down(sm) {
		//padding: 0 1rem 0 1.5rem;
	}
	&--white,
	&--inner  {
		background: cl(white);
		box-shadow: rem(0 3px 5px cl(black, .05));
		.header__nav {
			& > ul {
				& > li {
					& > a {
						color:cl(primary);
					}
				}
			}
		}
		.btn--white {
			border-color:cl(red)!important;
		}
		.logo--main {
			width:rem(360px);
		}
	}
	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: cl(primary);
		height: rem(40px);
		color:cl(white);
		padding: rem(0 30px);
		position: relative;
		z-index: 5;
		@include media-breakpoint-down(xs) {
			padding: 0 1rem;
		}
	}
	&__social {
		display: flex;
		align-items: center;
		gap:rem(25px);
		a {
			color:cl(white);
			font-size: rem(18px);
			&:hover,
			&:focus {
				text-decoration: none;
				color:cl(secondary);
			}
		}
	}
	&__actions {
		list-style: none;
		display: flex;
		align-items: center;
		gap:rem(20px);
		margin: 0;
		li {
			position: relative;
			&:not(:first-child) {
				&:before {
					content: "";
					position: absolute;
					left:rem(-10px);
					top:0;
					width:1px;
					height: rem(25px);
					background: cl(white, .43);
				}
			}
		}
		a {
			display: inline-block;
			vertical-align: top;
			padding: rem(5px);
			font-size: rem(18px);
			color:cl(white);
			white-space: nowrap;
			&:hover,
			&:focus {
				text-decoration: none;
				color:cl(secondary);
			}
		}
	}
	&__translate {
		position: absolute;
		right:0;
		top:-999px;
		background: cl(primary);
		padding: rem(16px);
		font-size: rem(16px);
		z-index: 10;
		transition: all .3s ease;
		&.show {
			top:100%;
		}
	}
	&__logo {
		width:rem(150px);
		flex-shrink: 0;
		img {
			max-width: 100%;
			height: auto;
		}
		&--nav {
			display: none;
			width:rem(150px);
			height: auto;
			top:1rem;
			@extend .pos-centerX;
			@include media-breakpoint-down(lg) {
				display: block;
			}
		}
	}
	&__bottom {
		color:cl(white);
		padding: rem(10px 30px);
		@include media-breakpoint-down(xs) {
			padding: .5rem 1rem;
		}
	}
	&__hold {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@include media-breakpoint-down(lg) {
			align-items: center;
		}
	}
	&__inner {
		width:100%;
		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
		}
	}
	&__panel {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		@include media-breakpoint-up(xl) {
			margin: rem(0 0 25px);
		}
		@include media-breakpoint-down(xs) {
			gap:2rem;
		}
	}
	&__right {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		gap:rem(25px);
		@include media-breakpoint-up(xl) {
			padding-left:30px;
		}
		@include media-breakpoint-down(sm) {
			gap:rem(16px);
		}
	}
	&__slogan {
		width:calc(100% - 4rem);
		color:cl(white);
		font-size: rem(16px);
		white-space: nowrap;
		overflow: hidden;
		margin: 0 2rem;
		//padding: 0 1rem;
		@include media-breakpoint-down(lg) {
			display: none;
		}
		span {
			//padding:0 0 0 100%;
			display:inline-block;
			animation: marquee 35s infinite linear;
		}
	}
	&__notification {
		flex-shrink: 0;
		width:rem(50px);
		height: rem(50px);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: cl(primary);
		color:cl(white);
		font-size: rem(20px);
		box-shadow:rem(0 9px 21px cl(black,.14));
		&:hover,
		&:focus {
			text-decoration: none;
			background: cl(red);
			color:cl(white);
		}
		@include media-breakpoint-down(xs) {
			top:5.5rem;
			right:1rem;
			width:rem(45px);
			height: rem(45px);
			font-size: rem(18px);
			box-shadow:rem(0 5px 10px cl(black,.04));
		}
	}
	&__nav {
		width:100%;
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		z-index: 2;
		@include media-breakpoint-down(lg) {
			position: fixed;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			padding: 9rem 0 0;
			background: cl(white);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			transition: all 0.3s linear;
			&.show {
				right:0;
				box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			}
		}
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		& > ul {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: rem(20px);
			list-style: none;
			margin: 0;
			@include media-breakpoint-down(lg) {
				height: 100%;
				width:100%;
				overflow: auto;
				display: block;
			}
			& > li {
				position: relative;
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;
					border-top:rem(1px solid cl(primary, 0.1));
					&:last-child {
						border-bottom:rem(1px solid cl(primary, 0.1));
					}
				}
				& > a {
					display: block;
					text-transform: uppercase;
					font-size: rem(20px);
					line-height: rem(22px);
					padding: rem(0 5px);
					font-weight: 700;
					color:cl(white);
					position: relative;
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 1.5rem 2rem;
						text-align: center;
						color:cl(primary);
					}
					&:hover {
						text-decoration: none;
						color:cl(red);
					}
					&.with-drop:after {
						@include media-breakpoint-down(lg) {
							content: '\f107';
							position: absolute;
							top:1.5rem;
							right: 1rem;
							font-family: 'Font Awesome 5 Free';
							font-weight: 900;
							transition: all .2s linear;
						}
					}
					&.with-drop.opened {
						background: cl(primary,.2);
						&:after {
							transform: rotate(180deg);
						}
					}
				}
				&.active {
					& > a {
						text-decoration: none;
						color:cl(red);
					}
				}
				&:hover {
					& > .drop {
						@include media-breakpoint-up(xl) {
							display: block;
						}
					}
				}
				&:nth-last-child(2),
				&:last-child {
					.drop {
						left:auto;
						right:0;
					}
				}
				& > .drop {
					position: absolute;
					top:rem(17px);
					padding-top: rem(16px);
					left:0;
					width:rem(250px);
					display: none;
					@include media-breakpoint-down(lg) {
						//display: block;
						position: static;
						width:100%;
						padding: 0;
						margin: 0;
					}
					ul {
						margin: 0;
						background: cl(primary);
						padding: rem(2px);
						list-style: none;
						box-shadow:rem(1px 1px 6px cl(black, .21));
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: cl(primary, .1);
						}
						li {
							position: relative;
							&:not(:last-child) {
								@include media-breakpoint-up(xl) {
									margin-bottom: 1px;
								}
							}
							a {
								display: block;
								font-size: rem(15px);
								line-height: rem(18px);
								color:cl(white);
								padding: rem(10px 10px 10px 20px);
								@include media-breakpoint-down(lg) {
									display: block;
									padding: 1.5rem 2rem;
									text-align: center;
									border-top:rem(1px solid cl(primary, .1));
									font-weight: 600;
									font-size: rem(16px);
									color:cl(black);
								}
								&:hover {
									text-decoration: none;
									background: cl(secondary);
									@include media-breakpoint-down(lg) {
										background: cl(primary, .4);
										color:cl(black);
									}
								}
							}
							&.active {
								& > a {
									text-decoration: none;
									background: cl(secondary);
									@include media-breakpoint-down(lg) {
										background: cl(primary, .4);
										color:cl(black);
									}
								}
							}
							&:hover {
								& > .drop {
									@include media-breakpoint-up(xl) {
										display: block;
									}
								}
							}
						}
						.drop {
							position: absolute;
							top:0;
							padding-left: .5rem;
							left:calc(100% - .5rem);
							width:rem(250px);
							display: none;
							@include media-breakpoint-down(lg) {
								//display: block;
								position: static;
								width:100%;
								padding: 0;
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
	&__rating {
		font-size: rem(14px);
		text-transform: uppercase;
		color:#231f20;
	}
	&__buttons {
		position: relative;
		display: flex;
		gap:rem(17px);
		align-items: center;
		@include media-breakpoint-down(xs) {
			padding: 1rem;
			justify-content: space-between;
			gap:0;
			width:100%;
		}

		.btn {
			@include media-breakpoint-down(xs) {
				width:48%;
			}
		}
	}
	&__open-nav,
	&__close-nav {
		display: none;
		position: relative;
		padding: 0;
		height:rem(20px);
		width:rem(26px);
		flex-shrink: 0;
		text-indent: -9999px;
		appearance: none;
		border: none;
		cursor: pointer;
		background: none;
		border:0;
		transition: all 0.3s ease  0.3s;
		@include media-breakpoint-up(sm) {
			margin: rem(0 0 0 32px);
		}
		&:focus {
			outline: none;
		}
		@include media-breakpoint-down(lg){
			display: block;
		}
		span,
		span::before,
		span::after {
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			height:rem(2px);
			background-color: cl(primary);
			content: "";
			transition: all 0.3s ease;
		}
		span {
			top:rem(9px);
			&:before {
				top:rem(-7px);
			}
			&:after{
				bottom:rem(-7px);
			}
		}
		&--active {
			position: absolute;
			top:1.5rem;
			right:1rem;
			&:before {
				display: none;
			}
			span {
				background: none;
				&:before {
					top: 0;
					transform:rotate(45deg);
				}
				&:after{
					bottom: 0;
					transform:rotate(-45deg);
				}
			}
		}
	}

	@-webkit-keyframes marquee {
		from {transform: translate(0, 0);}
		to {transform: translate(-100%, 0)}
	}
	@keyframes marquee{
		from {transform: translate(0, 0);}
		to {transform: translate(-100%, 0)}
	}
}

.translated-ltr,
.translated-rtl {
	.header {
		margin-top: rem(39px);
		.header-inner {
			padding-top: 2.875rem;
		}
		.header-close_nav {
			top: 3.75rem;
		}
	}
}
.goog-te-gadget-simple {
	background: transparent!important;
	border:0!important;
	.goog-te-menu-value {
		color:cl(white)!important;
		font-family: $font;
		span {
			color:cl(white)!important;
		}
	}
	.goog-te-gadget-icon {
		display: none!important;
	}
}