.information {
    padding: rem(125px 0 60px);
    @include media-breakpoint-down(xs) {
        padding: 4rem 0 3.75rem;
    }
    &__text {
        font-size: rem(18px);
        line-height: 1.5;
        ul {
            li {
                list-style: none;
                padding: rem(0 0 0 25px);
                margin: rem(0 0 15px);
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    left:0;
                    top:rem(9px);
                    width:rem(9px);
                    height: rem(9px);
                    border-radius: 50%;
                    background-color: cl(secondary);
                }
            }
        }
    }
    &__side {
        position: relative;
        @include media-breakpoint-down(md) {
            margin-bottom: 3rem;
        }
    }
    &__image {
        display: block;
        width:100%;
        height: auto;
        box-shadow:rem(0 3px 51px cl(black, .34));
        border-radius: rem(60px);
        transform: perspective(1000px) translateZ(0px) translateX(0px) translateY(0px) rotateY(15deg);
    }
    &__leaf {
      position: absolute;
      width: rem(150px);
      height: auto;
      transform: rotate(70deg);
      right: 5%;
      bottom: -15%;
      @include media-breakpoint-down(xs) {
        width: rem(100px);
      }
    }
}